import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useAppConfig } from '@rugby-au/app-config';
import { checkToken, encodeParamsFromPath, getClientAuthProps, getItem, setItem, useFetch } from '@rugby-au/services';
import { FormRefProps } from '@rugby-au/form';
import { useMultiFormProvider } from '@rugby-au/multi-form';

import { MFALayoutRefProp } from './MultiFactorAuthLayout';
import { SignInColumnLayout } from './SignInColumnLayout';
import { SignInDefaultLayout } from './SignInDefaultLayout';
import { SignInProps } from './SignInLayout';
import { useRouter } from 'next/router';
import { SamlResponse } from '@rugby-au/saml-form';

const THIRTY_DAYS_IN_MINS = 60 * 24 * 30;

export const useSignInLayout = ({
  config,
  redirectOnLogin,
  layoutRef,
  triggerNext,
  showSignUp,
  redirectPath,
  nextButtonProps,
  header = 'Login',
  subTextLine1 = 'New member or existing member without a Rugby Xplorer account?',
  subTextLine2 = 'Existing member with a Rugby Xplorer account?',
  subTextLine3 = 'Login now to complete your purchase.',
  layout = 'default',
  layoutProps,
}: SignInProps) => {
  const { setNavigation, setNotification, setIsLogged } = useAppConfig();
  const formRef = useRef<FormRefProps>(null);
  const mfaLayoutRef = useRef<MFALayoutRefProp>(null);
  const { multiFormData } = useMultiFormProvider();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState<boolean | number>(false);
  const [samlResponse, setSamlResponse] = useState<SamlResponse>();
  const checkedUserRef = useRef(false);
  const { fetchRequest } = useFetch({});

  const getHeaders = useCallback(async () => {
    let headers: any = {};
    const client = await getItem('client');
    if (client) {
      headers.clientId = client.username;
      headers.codeChallenge = client.codeChallenge;
    }
    return headers;
  }, []);
  const params = useRouter();

  //Check if user already logged in, Since AsyncStorage use windows variable we need to make sure that this run after the page is build
  useEffect(() => {
    if (!checkedUserRef.current) {
      checkedUserRef.current = true;
      getItem('client')
        .then((client: any) => {
          const query = params.query;
          // console.log('useSignInLayout.tsx line 61 - query ', JSON.stringify(query));
          if (query.redirectUrl) {
            const redirectUrl = new URL(decodeURIComponent(query.redirectUrl as string));
            query.redirectPath = redirectUrl.pathname + redirectUrl.search;
          }
          client.redirectPath = query.redirectPath ?? client.redirectPath;
          client.codeChallenge = query.codeChallenge ?? client.codeChallenge;
          client.clientId = query.clientId ?? client.clientId;
          client.SAMLRequest = { request: query.SAMLRequest, relayState: query.RelayState };
          fetchRequest(checkToken, client)
            .then(async (user: any) => {
              if (user && user.userId && !user.error) {
                setIsLogged(true);
                if (user.SAMLResponse) {
                  setSamlResponse(user.SAMLResponse);
                } else if (redirectOnLogin) {
                  if (triggerNext) {
                    await triggerNext();
                    setIsLoading(false);
                  } else {
                    let navigationPath = '/';
                    let redirectTo = await getItem('redirectTo');
                    if (redirectTo) {
                      navigationPath = redirectTo;
                      setItem('redirectTo', null);
                      setNavigation && setNavigation(navigationPath);
                    } else {
                      let authCode = user?.authCode;
                      if (!authCode) {
                        authCode = client?.authCode;
                      }
                      let redirectUrl = user?.redirectUrl;
                      if (!redirectUrl) {
                        redirectUrl = 'https://www.rugby.com.au';
                      }
                      if (client && client.redirectPath) {
                        redirectUrl = redirectUrl?.substring(redirectUrl.length - 1) === '/' ? redirectUrl?.substring(0, redirectUrl.length - 1) : redirectUrl;
                        // let _redirectPath = client.redirectPath?.startsWith('/') ? client.redirectPath : `/${client.redirectPath}`;
                        // redirectUrl = `${redirectUrl}${_redirectPath}`;
                        redirectUrl = `${redirectUrl}${encodeParamsFromPath(client.redirectPath)}`;
                      }
                      if (authCode) {
                        if (client?.noPkce) {
                          authCode += `^|^.u6YT^${user?.token}`;
                        }
                        redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}authCode=${authCode}&clientId=${client?.username}`;
                      } else {
                        console.error('file: index.tsx:29 ~ No authCode: ', JSON.stringify(user), JSON.stringify(client));
                      }
                      // console.log('useSignInLayout.tsx line 110 - redirectUrl: ', redirectUrl);
                      window.location.href = redirectUrl;
                    }
                  }
                }
              } else {
                setIsLoading(false);
              }
            })
            .catch(e => {
              console.error('file: index.tsx:29 ~ getItem ~ e:', e);
              setIsLogged(false);
              setIsLoading(false);
              //setNavigation && setNavigation(`/login${params.asPath}`);
            });
        })
        .catch(e => {
          console.error('file: index.tsx:35 ~ getItem ~ e:', e);
          setIsLogged(false);
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const beforeNext = async () => {
      const result = await formRef?.current?.onSubmit();
      if (result?.response?.error) {
        let message = `${result.response.error.message} ${result.response.error.originalError}`;
        if (result.response.error.code === 401) {
          message = 'Invalid email or password';
        }
        setNotification({ severity: 'error', message });
      }
      return { isValid: result?.isValid ?? false };
    };
    const beforeBack = async () => {
      return { isValid: true, message: 'success' };
    };
    if (layoutRef) {
      layoutRef.current = {
        beforeNext,
        beforeBack,
      };
    }
  }, [layoutRef, params, setNavigation, setNotification]);

  const onSuccessfulLogin = useCallback(
    async (response: any) => {
      if (!response.error && response.userId) {
        const client = await getItem('client');
        let authCode = response?.authCode;
        if (authCode && client) {
          setItem('client', { ...client, authCode: response.authCode });
        }
        await setItem('user', response);
        setIsLogged(true);

        if (response.SAMLResponse) {
          setIsLoading(true);
          setSamlResponse(response.SAMLResponse);
        } else if (triggerNext) {
          await triggerNext();
        } else {
          if (params.query.redirectPath) {
            client.redirectPath = decodeURIComponent(params.query.redirectPath as string);
          }
          let redirectUrl = response?.redirectUrl;
          if (client && client.redirectPath) {
            redirectUrl = redirectUrl?.substring(redirectUrl.length - 1) === '/' ? redirectUrl?.substring(0, redirectUrl.length - 1) : redirectUrl;
            redirectUrl = `${redirectUrl}${encodeParamsFromPath(client.redirectPath)}`;
          }
          if (authCode) {
            if (client?.noPkce) {
              authCode += `^|^.u6YT^${response?.token}`;
            }
            redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}authCode=${authCode}&clientId=${client?.username}`;
          }
          window.location.href = redirectUrl;
        }
      }
    },
    [params, setIsLogged, triggerNext],
  );

  const cleanSubmitData = useCallback(
    async (submitData: any) => {
      try {
        const _submitData = { ...submitData };
        if (submitData.rememberMe) {
          _submitData.expirationInMinutes = THIRTY_DAYS_IN_MINS;
          setRememberMe(THIRTY_DAYS_IN_MINS);
        }

        const query = params.query;
        if (query?.clientId) {
          if (query.redirectUrl) {
            const redirectUrl = new URL(decodeURIComponent(query.redirectUrl as string));
            query.redirectPath = redirectUrl.pathname + redirectUrl.search;
          }
          const apiToken = await getClientAuthProps({
            username: query.clientId as string,
            // redirectUrl: query.redirectUrl as string,
            redirectPath: query.redirectPath as string,
            codeChallenge: query.codeChallenge as string,
          });

          if (apiToken?.error || !apiToken || !apiToken.username) {
            setNavigation && setNavigation('/404');
          }
          if (query.redirectPath) {
            apiToken.redirectPath = encodeURIComponent(query.redirectPath as string);
          }
          const client = await getItem('client');
          client.SAMLRequest = { request: params.query.SAMLRequest, relayState: params.query.RelayState };
          await setItem('client', { ...client, ...apiToken });
          _submitData.samlRequest = client.SAMLRequest;
        }
        return _submitData;
      } catch (error) {
        throw error;
      }
    },
    [params.query, setNavigation],
  );

  const content = useMemo(() => {
    const onSignIn = async (hrefParams?: string) => {
      if (redirectPath) {
        await setItem('redirectTo', `${redirectPath}?productId=${multiFormData?.__meta.productId}`);
      }
      setNavigation && setNavigation(`/register${hrefParams ? `?${hrefParams}` : ''}`);
    };

    const onFinished = async (result: any) => {
      if (!result?.error && result?.response?.userId) {
        onSuccessfulLogin(result.response);
        return { message: 'success', isValid: true };
      } else if (result?.response?.isMfaEnabled && result?.response?.token) {
        localStorage.setItem('m-u', result.response.token);
        setModalVisible(true);
        nextButtonProps && nextButtonProps({ title: 'Verify' });
        // set next button to call mfa verify layout ref available
        return { message: 'mfa', isValid: false };
      } else {
        return { message: 'error', isValid: false };
      }
    };

    if (layout === 'column' && layoutProps) {
      return (
        <SignInColumnLayout
          layoutRef={layoutRef}
          header={header}
          showSignUp={showSignUp}
          subTextLine1={subTextLine1}
          formRef={formRef}
          config={config}
          onFinished={onFinished}
          getHeaders={getHeaders}
          cleanSubmitData={cleanSubmitData}
          layoutProps={layoutProps}
          onSignIn={onSignIn}
        />
      );
    } else {
      return (
        <SignInDefaultLayout
          layoutRef={layoutRef}
          header={header}
          showSignUp={showSignUp}
          subTextLine1={subTextLine1}
          subTextLine2={subTextLine2}
          subTextLine3={subTextLine3}
          formRef={formRef}
          config={config}
          onFinished={onFinished}
          getHeaders={getHeaders}
          cleanSubmitData={cleanSubmitData}
          onSignIn={onSignIn}
        />
      );
    }
  }, [
    config,
    getHeaders,
    cleanSubmitData,
    header,
    layout,
    layoutProps,
    layoutRef,
    multiFormData?.__meta?.productId,
    nextButtonProps,
    onSuccessfulLogin,
    redirectPath,
    setNavigation,
    showSignUp,
    subTextLine1,
    subTextLine2,
    subTextLine3,
  ]);

  return { content, modalVisible, setModalVisible, onSuccessfulLogin, mfaLayoutRef, rememberMe, isLoading, samlResponse };
};
